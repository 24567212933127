import React from 'react';
import { useIntl } from 'react-intl';

import { AltBuilding } from '../../../store/alt-buildings/types';
import {
  Container, Label, StyledModalWrapper, Title,
} from './styles';
import { AltBuildingModalBody } from '../alt-building-modal-body/component';

export interface SwitchAltBuildingsModalProps {
  altBuildings: Array<AltBuilding>;
  isVisible: boolean;
  onClose: VoidFunction;
}

export const SwitchAltBuildingModal = ({
  isVisible,
  onClose,
  altBuildings,
}: SwitchAltBuildingsModalProps): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledModalWrapper
      content={(
        <Container data-testid="building-switch-modal">
          <Title>{intl.formatMessage({ id: 'buildings' })}</Title>
          <Label>{intl.formatMessage({ id: 'switch_buildings' })}</Label>
          <AltBuildingModalBody altBuildings={altBuildings} onClose={onClose} />
        </Container>
      )}
      onClose={onClose}
      visible={isVisible}
      withExitButton
    />
  );
};
